import {
  Answer,
  MultiChoiceQuestion,
  MultiChoiceResponse,
  createMultiChoiceAnswer,
  isMultiChoiceResponse,
} from "@cur8/questionnaire";
import { useCallback, useMemo } from "react";
import { Checkbox } from "render/ui/form/Checkbox";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface MultiChoiceInputWithDescriptionsProps<
  Value,
  Statement,
  ChoiceStatement
> {
  question: MultiChoiceQuestion<Value, Statement, ChoiceStatement>;
  answer?: Answer;
  onAnswer: (answer: Answer<MultiChoiceResponse>) => void;
}

export function MultiChoiceInputWithDescriptions<
  Value,
  Statement extends React.ReactNode,
  ChoiceStatement extends Record<"description" | "label", React.ReactNode>
>({
  question,
  answer,
  onAnswer,
}: MultiChoiceInputWithDescriptionsProps<Value, Statement, ChoiceStatement>) {
  const indices = useMemo(() => {
    const indices = [];
    if (answer && isMultiChoiceResponse(answer.response)) {
      indices.push(...answer.response.choiceIndices);
    }
    return new Set<number>(indices);
  }, [answer]);

  const handleChoice = useCallback(
    (choiceIndex: number) => {
      if (indices.has(choiceIndex)) {
        indices.delete(choiceIndex);
      } else {
        indices.add(choiceIndex);
      }

      const answer = createMultiChoiceAnswer([...indices]);

      onAnswer(answer);
    },
    [indices, onAnswer]
  );

  return (
    <fieldset data-hj-suppress className={styles.MultiChoiceInput}>
      {question.choices.map((choice, choiceIndex) => {
        const { label, description } = choice.statement;
        return (
          <Checkbox
            key={choiceIndex}
            bordered
            checked={indices.has(choiceIndex)}
            inverted
            name={choice.value as string}
            value={label as string}
            onChange={() => handleChoice(choiceIndex)}
          >
            <div>
              <Typography variant="cta">{label}</Typography>
              <Typography variant="paragraph-small" color="subtle">
                {description}
              </Typography>
            </div>
          </Checkbox>
        );
      })}
    </fieldset>
  );
}

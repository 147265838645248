import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { Typography } from "render/ui/presentation/Typography";
import { Button } from "render/ui/trigger/Button";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

type ConsentButtonProps = {
  consentId: string;
  documentUri: string;
  signed: boolean;
  signatureId: string | undefined;
};

export function ConsentButton({
  consentId,
  documentUri,
  signed,
  signatureId,
}: ConsentButtonProps) {
  const { mutate: consentMutation } = useGiveConsentMutation();
  const { mutate: revokeConsentMutation } = useRevokeConsentMutation();

  if (signed) {
    return (
      <Button
        variant="outlined"
        onClick={({ currentTarget }) => {
          revokeConsentMutation({ consentId: signatureId });
          scrollToTop(currentTarget);
        }}
      >
        <Typography variant="cta" color="warning">
          <Trans.RevokeConsent />
        </Typography>
      </Button>
    );
  }

  return (
    <div className={styles.renewConsentButton}>
      <Button
        variant="active"
        onClick={({ currentTarget }) => {
          consentMutation({ documentUri, consentMetadataId: consentId });
          scrollToTop(currentTarget);
        }}
      >
        <Typography variant="cta">
          {signatureId ? <Trans.RenewConsent /> : <Trans.Consent />}
        </Typography>
      </Button>
    </div>
  );
}

function scrollToTop(element: Element) {
  getScrollParent(element)?.scrollTo({ behavior: "smooth", top: 0 });
}

function getScrollParent(element: Element | null) {
  if (element == null) {
    return element;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return getScrollParent(element.parentElement);
}

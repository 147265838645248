import { PathLink, useNav } from "@pomle/react-router-paths";
import { ReactComponent as ChevronIcon } from "assets/chevron-right.svg";
import { useLatestOptionalConsentsByType } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { paths } from "render/routes/paths";
import { Typography } from "render/ui/presentation/Typography";
import { ConsentCard } from "render/views/ProfileView/components/DataAndPrivacySection/components/ConsentCard";
import { useConsentsMap } from "./hooks/useConsentsMap";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function DataAndPrivacySection() {
  const patientQuery = usePatientQuery();
  const nav = {
    consents: useNav(paths.dataAndPrivacyConsents),
    exportData: useNav(paths.dataAndPrivacyExportData),
  };
  const { other } = useConsentsMap();
  const { data: latestConsents } = useLatestOptionalConsentsByType();
  const hasOtherConsents =
    latestConsents?.other && latestConsents.other.length > 0;
  const hasStudyConsents =
    latestConsents?.studies && latestConsents.studies.length > 0;

  if (latestConsents == null) {
    return undefined;
  }

  const navToConsent = (consentId: string) => nav.consents.go({ consentId });

  return (
    <section data-hj-suppress className={styles.studiesSection}>
      <div className={styles.title}>
        <Typography variant="title-large">
          <Trans.Title />
        </Typography>
        <Typography variant="paragraph">
          <Trans.Paragraph link={other.PRIVACYPOLICY.getUrl("en")} />
        </Typography>
      </div>

      {hasOtherConsents ? (
        <section className={styles.section}>
          <Typography variant="title-medium">
            <Trans.Products />
          </Typography>
          <div className={styles.cardsWrapper}>
            {latestConsents.other.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={navToConsent}
              />
            ))}
          </div>
        </section>
      ) : undefined}

      {hasStudyConsents ? (
        <section className={styles.section}>
          <Typography variant="title-medium">
            <Trans.ResearchStudies />
          </Typography>
          <div className={styles.cardsWrapper}>
            {latestConsents.studies.map((consent) => (
              <ConsentCard
                key={consent.relevantSignature?.consentId}
                consent={consent}
                onOpenConsentClick={navToConsent}
              />
            ))}
          </div>
          <Typography variant="paragraph-small" color="subtle">
            <Trans.ResearchStudiesDescription />
          </Typography>
        </section>
      ) : undefined}

      {patientQuery.data?.hasVerifiedIdentity && (
        <section className={styles.section}>
          <Typography variant="title-medium">
            <Trans.GdprExport />
          </Typography>
          <PathLink className={styles.gdpr} to={nav.exportData.to({})}>
            <div className={styles.text}>
              <Typography variant="paragraph-medium" color="default">
                <Trans.RequestPersonalData />
              </Typography>
              <Typography variant="paragraph-x-small" color="subtle">
                <Trans.CreateFiles />
              </Typography>
            </div>
            <ChevronIcon />
          </PathLink>
        </section>
      )}
    </section>
  );
}

import { InteractionType } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import { ViewStack } from "@pomle/react-viewstack";
import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import {
  SeverityLevel,
  useAppInsights,
} from "render/context/AppInsightsContext";
import { useConfig } from "render/context/ConfigContext";
import {
  locale2lang,
  useInternationalization,
} from "render/context/InternationalizationContext";
import { LogoView } from "render/views/LogoView";
import { useMSAL, useMsalAuthentication } from "../../MSALContext";
import { AuthenticatedProps } from "../../types";
import { useQueryParams, useRouter } from "@pomle/react-router-paths";
import { codecs, createQuery } from "@pomle/paths";

const authSearch = createQuery({
  authmethod: codecs.string,
});

export function Authenticated({
  children,
  ErrorView,
}: PropsWithChildren<AuthenticatedProps>) {
  const { locale } = useInternationalization();
  const config = useConfig();
  const isAuthenticated = useIsAuthenticated();
  const { session, isReady: isMsalReady } = useMSAL();
  const appInsights = useAppInsights();
  const scopes = config.appConfig.msal.scopes.token;
  const [params] = useQueryParams(authSearch);
  const router = useRouter();

  const redirectStartPage = router.window.location.toString();
  const redirectUri = router.window.location.origin;

  useEffect(() => {
    if (session) {
      return;
    }
    appInsights.trackEvent(
      { name: "before-auth" },
      { target: redirectStartPage, origin: redirectUri }
    );
  }, [session, redirectStartPage, redirectUri, appInsights]);

  const authMethod = useMemo(() => {
    const allowedPreferredLoginMethods = new Set(["phoneauth", "bankid"]);
    const method = params.authmethod[0] ?? "";

    if (allowedPreferredLoginMethods.has(method)) {
      return method;
    }

    return "";
  }, [params.authmethod]);

  const { error, login } = useMsalAuthentication(InteractionType.Redirect, {
    scopes,
    redirectStartPage,
    redirectUri,
    extraQueryParameters: {
      ui_locales: locale2lang(locale),
      acr_values: `authmethod:${authMethod}`,
    },
  });
  const loginCallback = useCallback(() => {
    return login(InteractionType.Redirect, {
      scopes,
      redirectStartPage,
      redirectUri,
    });
  }, [login, scopes, redirectStartPage, redirectUri]);

  useEffect(() => {
    if (!error) {
      return;
    }

    appInsights.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  }, [error, appInsights]);

  useEffect(() => {
    // Means the ID token has expired and we need to re-login
    if (error?.errorCode === "invalid_grant") {
      login();
    }
  }, [error?.errorCode, login]);

  // if Id token has expired, we don't want to show an error screen
  if (error && error.errorCode === "invalid_grant") {
    return null;
  }

  if (error) {
    return <ErrorView login={loginCallback} error={error} />;
  }

  const isReady = !!(isAuthenticated && session && isMsalReady);

  return (
    <ViewStack>
      {isReady && <>{children}</>}
      <div
        style={{
          pointerEvents: isReady ? "none" : "initial",
          transition: "opacity 0.5s ease-in",
          transitionDelay: "1.5s",
          opacity: isReady ? 0 : 1,
        }}
      >
        <LogoView />
      </div>
    </ViewStack>
  );
}

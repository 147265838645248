import { Slot } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { Accordion } from "render/ui/presentation/Accordion";
import { Typography } from "render/ui/presentation/Typography";
import { ReactComponent as CheckMarkIcon } from "./assets/checkmark.svg";
import { ReactComponent as CrossIcon } from "./assets/cross.svg";
import DoctorImage from "./assets/doctor.webp";
import BookingDetailSquareImage from "./assets/img/booking-detail-square.webp";
import BookingDetailWideImage from "./assets/img/booking-detail-wide.webp";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { StepsSection } from "./components/StepsSection";
import { TipsSection } from "./components/TipSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";
import { CompleteProfileButton } from "./components/CompleteProfileButton";

interface AppointmentInfoSectionProps {
  slot: Slot;
  visitId: string;
}

export function AppointmentInfoSection({
  slot,
  visitId,
}: AppointmentInfoSectionProps) {
  const nav = {
    root: useNav(paths.root),
  };
  const timeZoneId = slot.room?.site?.timeZoneId;
  const date = useMemo(
    () => slot.startTime.setZone(timeZoneId),
    [slot.startTime, timeZoneId]
  );
  const startTime = useMemo(
    () => slot.startTime.setZone(timeZoneId),
    [slot.startTime, timeZoneId]
  );
  const endTime = useMemo(
    () => slot.endTime.setZone(timeZoneId),
    [slot.endTime, timeZoneId]
  );
  const duration = useMemo(
    () => endTime.diff(startTime, "minute").minutes,
    [startTime, endTime]
  );

  const address = slot.room?.site?.address;
  const map = encodeURIComponent(
    `${address?.city}, ${address?.country}, ${address?.street}, ${address?.postalCode}`
  );

  return (
    <div className={styles.AppointmentInfoSection}>
      <section className={styles.head}>
        <picture>
          <source
            srcSet={BookingDetailSquareImage}
            media="(max-width: 980px)"
          />
          <source srcSet={BookingDetailWideImage} />
          <img className={styles.image} alt="room" width="3040" height="1200" />
        </picture>
        <div className={styles.header}>
          <div className={styles.logo}>
            <PathLink className={styles.link} to={nav.root.to({})}>
              <CrossIcon />
            </PathLink>
            <LogoIcon />
          </div>
          <div className={styles.title}>
            <Typography variant="title-large">
              <Trans.Title />
            </Typography>
            <div className={styles.badge}>
              <CheckMarkIcon />
              <Typography variant="subtitle-small">
                <Trans.Status location={slot.room?.site?.siteName ?? ""} />
              </Typography>
            </div>
          </div>
        </div>
      </section>

      <div className={styles.content}>
        <div className={styles.body}>
          <section className={styles.sectionTime}>
            <div className={styles.date}>
              <Typography variant="number-medium">
                {date.toFormat("dd")}
              </Typography>

              <Typography variant="paragraph-medium" color="subtle">
                {date.toFormat("MMMM")}, {date.toFormat("EEEE")}
              </Typography>
            </div>
            <div className={styles.hour}>
              <Typography variant="number-medium">
                {startTime.toFormat("HH:mm")}
              </Typography>
              <Typography variant="paragraph-medium" color="subtle">
                <Trans.EndTime
                  duration={duration}
                  endTime={endTime.toFormat("HH:mm")}
                />
              </Typography>
            </div>
          </section>

          <section className={styles.stepsSection}>
            <div className={styles.stepsContent}>
              <div className={styles.stepsContainer}>
                <StepsSection slot={slot} visitId={visitId} />
              </div>
              <div className={styles.tipsContainer}>
                <TipsSection />
              </div>
            </div>
          </section>

          <div className={styles.separator} />

          <section className={styles.sectionLocation}>
            <div className={styles.findUs}>
              <Trans.FindUs.Title />
            </div>
            <a
              className={styles.address}
              target="_blank"
              rel="noreferrer"
              href={`http://google.com/maps/?q=${map}`}
            >
              {address?.street}
            </a>
            <div className={styles.arival}>
              <Trans.FindUs.Description />
            </div>
          </section>

          <section className={styles.sectionDoctorImage}>
            <img src={DoctorImage} alt="doctor" width="100%" height="100%" />
          </section>

          <section className={styles.sectionFAQ}>
            <Typography variant="title-medium">
              <Trans.FAQ.Title />
            </Typography>
            <ol className={styles.sectionFAQList}>
              <li className={styles.sectionFAQListItem}>
                <Accordion
                  header={<Trans.FAQ.Skin.Header />}
                  text={
                    <div className={styles.explanation}>
                      <Trans.FAQ.Skin.Body />
                    </div>
                  }
                />
              </li>
              <li className={styles.sectionFAQListItem}>
                <Accordion
                  header={<Trans.FAQ.Heart.Header />}
                  text={
                    <div className={styles.explanation}>
                      <Trans.FAQ.Heart.Body />
                    </div>
                  }
                />
              </li>
              <li className={styles.sectionFAQListItem}>
                <Accordion
                  header={<Trans.FAQ.Blood.Header />}
                  text={
                    <div className={styles.explanation}>
                      <Trans.FAQ.Blood.Body />
                    </div>
                  }
                />
              </li>
              <li className={styles.sectionFAQListItem}>
                <Accordion
                  header={<Trans.FAQ.Consultation.Header />}
                  text={
                    <div className={styles.explanation}>
                      <Trans.FAQ.Consultation.Body />
                    </div>
                  }
                />
              </li>
            </ol>
          </section>
          <section className={styles.mobileProfileButton}>
            <CompleteProfileButton slot={slot} visitId={visitId} />
          </section>
        </div>
      </div>
    </div>
  );
}

import { ComponentProps } from "react";
import { Typography } from "render/ui/presentation/Typography";

export function formatToDesignSystem(elements: HTMLCollectionOf<Element>) {
  return Array.from(elements)
    .filter((element) => element.tagName in variants)
    .map((element) => {
      const variant = variants[element.tagName];
      const parsedInnerHTML = element.innerHTML.replace(
        /href="/g,
        `target="_blank" rel="noreferrer" href="`
      );
      return (
        <Typography key={parsedInnerHTML} variant={variant}>
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    });
}

const variants: Record<string, ComponentProps<typeof Typography>["variant"]> = {
  P: "paragraph-medium",
  H1: "title-medium",
  H2: "paragraph-large",
};

import { APITypesV1 } from "@cur8/api-client";
import { ReactComponent as CheckmarkIcon } from "assets/checkmark.svg";
import { ReactComponent as ClockIcon } from "assets/error-clock.svg";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import { DateTime } from "luxon";
import { FC } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

type ConsentStateProps = {
  signature: APITypesV1.ConsentSummary | undefined;
};

export function ConsentState({ signature }: ConsentStateProps) {
  if (!signature) {
    return;
  }
  if (signature.revoked) {
    return (
      <ConsentStatus
        color="warning"
        date={signature.revokedAt!}
        Icon={ErrorIcon}
        Text={Trans.Revoked}
      />
    );
  }
  if (signature.expired) {
    return (
      <ConsentStatus
        color="warning"
        date={signature.expiresAt}
        Icon={ClockIcon}
        Text={Trans.Expired}
      />
    );
  }
  if (signature.optedOut) {
    return (
      <ConsentStatus
        date={signature.createdAt}
        Icon={CheckmarkIcon}
        Text={Trans.OptedOut}
      />
    );
  }
  return (
    <ConsentStatus
      date={signature.createdAt}
      Icon={CheckmarkIcon}
      Text={Trans.Agreed}
    />
  );
}

type ConsentStatusProps = {
  color?: "warning";
  date: string;
  Icon: FC;
  Text: (props: { date: string }) => JSX.Element;
};

function ConsentStatus({ color, date, Icon, Text }: ConsentStatusProps) {
  return (
    <div className={styles.studyState} data-warn={color === "warning"}>
      <Icon />
      <Typography variant="subtitle-medium" color={color}>
        <Text date={DateTime.fromISO(date).toFormat("DD")} />
      </Typography>
    </div>
  );
}

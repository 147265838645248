import { useCallback, useEffect, useRef, useState } from "react";
import { Typography } from "render/ui/presentation/Typography";
import { AnimatedLine } from "render/views/PostScanShareView/components/IntroText/components/AnimatedLine/AnimatedLine";
import { splitText } from "render/views/PostScanShareView/components/IntroText/lib";
import { Timings, Transforms } from "../../animations";
import styles from "./styles.module.sass";

interface IntroTextProps {
  text: string;
  playAnimation: boolean;
  onAnimationEnded?: () => void;
}

export function IntroText({
  text,
  playAnimation,
  onAnimationEnded,
}: IntroTextProps) {
  const [lines, setLines] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const introTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setIsMobile(window.innerWidth < 768);
    });

    observer.observe(document.body);
  }, []);

  useEffect(() => {
    if (!introTextRef.current) {
      return;
    }

    const container = introTextRef.current;

    const observer = new ResizeObserver(() => {
      const lines = splitText(text, container.clientWidth, isMobile);

      setLines(lines);
    });

    observer.observe(container);

    return () => {
      observer.disconnect();
      setLines([]);
    };
  }, [text, isMobile]);

  const [completedAnimations, setCompletedAnimations] = useState<number>(0);

  const handleOnAnimationEnded = () =>
    setCompletedAnimations((prev) => prev + 1);

  useEffect(() => {
    if (!lines.length) {
      return;
    }
    if (!onAnimationEnded) {
      return;
    }

    // note(ford): check if both intro and outro animations have completed
    if (completedAnimations !== lines.length * 2) {
      return;
    }

    onAnimationEnded();
  }, [completedAnimations, onAnimationEnded, lines]);

  const calcDelay = useCallback(
    (lineNumber: number) => {
      const delays = [
        // In delay
        lineNumber * Timings.introTextLineDelaySec,
        // Out delay
        Timings.introTextLineInDurationSec +
          Timings.introTextIdleDurationSec +
          lines.length * Timings.introTextLineDelaySec +
          lineNumber * Timings.introTextLineDelaySec,
      ];

      return delays.map((delay) => `${delay}s`).join(", ");
    },
    [lines]
  );

  const calcInTranslateY = useCallback(
    (lineNumber: number) => {
      const incrementY = isMobile
        ? Transforms.introTextLineInMobileIncrementTranslateY
        : Transforms.introTextLineInDesktopIncrementTranslateY;

      return Transforms.introTextLineBaseTranslateY + lineNumber * incrementY;
    },
    [isMobile]
  );

  const calcOutTranslateY = useCallback(
    (lineNumber: number) => {
      const incrementY = isMobile
        ? Transforms.introTextLineOutMobileIncrementTranslateY
        : Transforms.introTextLineOutDesktopIncrementTranslateY;

      return (
        Transforms.introTextLineBaseTranslateY +
        (lines.length - lineNumber) * incrementY
      );
    },
    [lines, isMobile]
  );

  return (
    <div className={styles.container}>
      <div ref={introTextRef} className={styles.introText}>
        <Typography variant={isMobile ? "title-medium" : "title-large"}>
          {lines.map((line, lineNumber) => {
            const delay = calcDelay(lineNumber);
            const inTranslateY = calcInTranslateY(lineNumber);
            const outTranslateY = calcOutTranslateY(lineNumber);

            return (
              <AnimatedLine
                key={line}
                line={line}
                duration={`${Timings.introTextLineInDurationSec}s, ${Timings.introTextLineOutDurationSec}s`}
                delay={delay}
                inTranslateY={`${inTranslateY}px`}
                outTranslateY={`-${outTranslateY}px`}
                playState={playAnimation ? "playing" : "paused"}
                onAnimationEnded={handleOnAnimationEnded}
              />
            );
          })}
        </Typography>
      </div>
    </div>
  );
}

import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";

export function getFirstAvailableSlot(
  api: APIClient,
  siteIds: string[],
  since: DateTime,
  until: DateTime
) {
  const req = api.bookingV2.querySlots({
    siteIds,
    pageSize: 1,
    startTime: { start: since.toISO(), end: until.toISO() },
  });

  return req;
}

export function querySlots(
  api: APIClient,
  {
    end,
    siteIds,
    start,
    pageSize,
  }: { siteIds: string[]; start: DateTime; end: DateTime; pageSize: number }
) {
  const req = api.bookingV2.querySlots({
    siteIds,
    pageSize,
    startTime: { start: start.toISO(), end: end.toISO() },
  });

  return req;
}

import { Typography } from "render/ui/presentation/Typography";
import { Button } from "render/ui/trigger/Button";
import { Card } from "render/ui/trigger/Card";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

type CreationFailedProps = {
  onRetry?: () => void;
  requestedOn: string;
};

export function CreationFailed({ requestedOn, onRetry }: CreationFailedProps) {
  return (
    <Card>
      <div className={styles.text}>
        <Typography variant="title-small">
          <Trans.CreationFailed />
        </Typography>
        <Typography variant="paragraph" color="subtle">
          <Trans.CustomerSupport />
        </Typography>
      </div>
      <Typography variant="subtitle-medium" color="subtle">
        <Trans.RequestedOn date={requestedOn} />
      </Typography>
      <Button variant="active" onClick={onRetry}>
        <Typography variant="cta">
          <Trans.TryAgain />
        </Typography>
      </Button>
    </Card>
  );
}

import { ComponentProps, useCallback } from "react";
import { usePostScanShareContext } from "render/context/PostScanShareContext";
import { useTracking } from "render/hooks/useTracking";
import { Typography } from "render/ui/presentation/Typography/Typography";
import { Trans } from "../../trans";
import BgImage from "../ScanCompleteAsset/assets/background.png";
import { ReactComponent as ShareIcon } from "./assets/share.svg";

import { postScanShareEvent } from "../../tracking";
import styles from "./styles.module.sass";

export function PostScanShareViewEntrypoint({
  onClick,
  ...props
}: ComponentProps<"button">) {
  const { setIsOpen, setConfig } = usePostScanShareContext();

  const { trackEvent } = useTracking();

  const openPostScanShareModal = useCallback(() => {
    setConfig({ variant: "modal" });
    setIsOpen(true);
  }, [setConfig, setIsOpen]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      trackEvent(postScanShareEvent.openModalClick());
      openPostScanShareModal();
      onClick?.(e);
    },
    [onClick, openPostScanShareModal, trackEvent]
  );

  return (
    <button className={styles.button} onClick={handleClick} {...props}>
      <div className={styles.card}>
        <img
          className={styles.bg}
          src={BgImage}
          alt="Shareable asset thumbnail"
          width={100}
          height={100}
        />

        <div className={styles.iconContainer}>
          <ShareIcon className={styles.icon} />
        </div>
      </div>

      <Typography variant="paragraph-x-small">
        <Trans.ScanCard />
      </Typography>
    </button>
  );
}

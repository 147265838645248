import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

type WhiteSpaceType =
  | "normal"
  | "pre"
  | "nowrap"
  | "pre-wrap"
  | "pre-line"
  | "break-spaces";

interface TypographyProps {
  as?: "span";
  variant:
    | "number-large"
    | "number-medium"
    | "number-small"
    | "title-large"
    | "title-medium"
    | "title-small"
    | "cta"
    | "paragraph-large"
    | "paragraph-medium"
    | "paragraph-small"
    | "paragraph-x-small"
    | "subtitle-medium"
    | "subtitle-small"
    | "label-medium"
    /**
     * @deprecated
     */
    | "paragraph"
    /**
     * @deprecated
     */
    | "description"
    /**
     * @deprecated
     */
    | "small"
    /**
     * @deprecated
     */
    | "text"
    /**
     * @deprecated
     */
    | "paragraph-sub";

  whiteSpace?: WhiteSpaceType;
  style?: Partial<React.CSSProperties>;
  color?: "info" | "subtle" | "warning" | "default";
}

const VARIANT_PROPS_MAP: Record<TypographyProps["variant"], VariantProps> = {
  "number-large": {
    tag: "span",
    className: styles.numberLarge,
  },
  "number-medium": {
    tag: "span",
    className: styles.numberMedium,
  },
  "number-small": {
    tag: "span",
    className: styles.numberSmall,
  },
  "title-large": {
    tag: "h1",
    className: styles.titleLarge,
  },
  "title-medium": {
    tag: "h2",
    className: styles.titleMedium,
  },
  "title-small": {
    tag: "h3",
    className: styles.titleSmall,
  },
  cta: {
    tag: "p",
    className: styles.cta,
  },
  "paragraph-large": {
    tag: "p",
    className: styles.paragraphLarge,
  },
  "paragraph-medium": {
    tag: "p",
    className: styles.paragraphMedium,
  },
  "paragraph-small": {
    tag: "p",
    className: styles.paragraphSmall,
  },
  "paragraph-x-small": {
    tag: "p",
    className: styles.paragraphXSmall,
  },
  "subtitle-medium": {
    tag: "p",
    className: styles.subtitleMedium,
  },
  "subtitle-small": {
    tag: "p",
    className: styles.subtitleSmall,
  },
  "label-medium": {
    tag: "p",
    className: styles.labelMedium,
  },
  /**
   * @deprecated
   */
  description: {
    tag: "p",
    className: styles.description,
  },
  /**
   * @deprecated
   */
  paragraph: {
    tag: "p",
    className: styles.p,
  },
  /**
   * @deprecated
   */
  "paragraph-sub": {
    tag: "p",
    className: styles.paragraphSub,
  },
  /**
   * @deprecated
   */
  small: {
    tag: "p",
    className: styles.small,
  },
  /**
   * @deprecated
   */
  text: {
    tag: "p",
    className: styles.text,
  },
};

type VariantProps = {
  className: string;
  tag: "h1" | "h2" | "h3" | "p" | "span";
};

export function Typography({
  as,
  children,
  color,
  style = {},
  variant,
  whiteSpace = "normal",
}: PropsWithChildren<TypographyProps>) {
  const { className, tag } = VARIANT_PROPS_MAP[variant];
  const Component = as ?? tag;

  return (
    <Component
      className={className}
      data-color={color}
      style={{ whiteSpace, ...style }}
    >
      {children}
    </Component>
  );
}
